import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import { get } from 'lodash'

import PageLayout from '../components/page-layout'
import { SectionText } from '../components/section'
import { GridSection } from '../components/grid'
import { getSEOFromPage } from '../components/seo'

import breakpoints from '../lib/constants/breakpoints.json'
import { formatWpImageForARYImage, mapStories } from '../lib/_helpers'
import { ThumbnailImageFragment } from '../lib/fragments'
import { formatLink } from '../lib/formatLink'

import iIntro from '../assets/imgs/home/intro_stories.png'
import iIntroMob from '../assets/imgs/home/intro_stories_mobile.png'

class PageStories extends React.Component {
  calcRows() {
    const { screenSize } = this.props

    if (screenSize.width <= breakpoints.mobilePortrait) {
      return 1
    } else if (screenSize.width <= breakpoints.tabletPortrait) {
      return 2
    } else if (screenSize.width <= breakpoints.tablet) {
      return 3
    } else {
      return 4
    }
  }

  render() {
    const { data, location, fadeWait } = this.props

    const _stories = get(data, 'stories.edges')

    return (
      <PageLayout
        seo={getSEOFromPage(get(data, 'page'))}
        location={location}
        pageIntroTitle="Stories"
        pageIntroImage={{
          src: iIntro,
          width: 2560,
          height: 1340,
        }}
        pageIntroImageMobile={{
          src: iIntroMob,
          width: 1080,
          height: 1340,
        }}
        pageIntroColour="purple"
        showFooter={true}
      >
        <SectionText
          title="Stories"
          body={`
					<p>A store of words pictures, sights & sounds to interest and inspire.</p>
					`}
          bgColour="purple"
          headerBgColour={true}
          fadeWait={fadeWait}
          showIndicator={true}
        />
        <GridSection
          colBase={this.calcRows()}
          title="Stories."
          colour="purple"
          textBlockClassName="textBlock--small"
          elementCTA="Read more"
          bodyIsHTML={true}
          elements={mapStories(_stories)}
        />
      </PageLayout>
    )
  }
}

const mapStateToProps = (state) => {
  const { ui } = state

  return {
    screenSize: ui.screenSize,
  }
}

export default connect(mapStateToProps)(PageStories)

export const query = graphql`
  query($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      title
      slug
      path
      wordpress_id
      seo_meta {
        title
        description
      }
    }
    stories: allWordpressPost(
      sort: { fields: [date], order: DESC }
      filter: { acf: { hide_from_stories: { eq: false } } }
    ) {
      edges {
        node {
          title
          id
          wordpress_id
          slug
          path
          link
          date(formatString: "YYYY MMMM DD")
          acf {
            thumbnail {
              ...ARYImageThumbnail
            }
            featured_article
            introduction
            hide_from_stories
          }
          categories {
            id
            name
          }
        }
      }
    }
  }
`
